import React, { Component } from "react";
import CService from "../components/service";
import MainLayout from "../layout/MainLayout";

class service extends Component {
  render() {
    return (
      <MainLayout>
        <CService mobx={this.props.mobx} />
      </MainLayout>
    );
  }
}

export default service;
