import { observable } from "mobx";
import firebase from "firebase/app";
import "firebase/database";

import lang from "./aircargo-1769f-export.json";

const getJSONvalue = (json, key) => {
  const keys = key.split(".");
  let jsont = json;
  for (let i = 0; i < keys.length; i++) {
    if (!jsont[keys[i]]) {
      return key;
    }
    jsont = jsont[keys[i]];
  }
  return jsont;
};

class Store {
  constructor() {
    if (process.env.NODE_ENV === "production") {
      var config = {
        databaseURL: "https://aircargo-1769f.firebaseio.com"
      };
      firebase.initializeApp(config);
      firebase
        .database()
        .ref("/")
        .once("value", snap => {
          this.lang = snap.val();
          this.loading.set(false);
        });
    }
    if (process.env.NODE_ENV === "development") {
      this.lang = lang;
      this.loading.set(false);
    }
  }

  loading = observable.box(true);

  lang = observable({});

  language = observable.box("en");

  getFixLangText = slug => {
    return getJSONvalue(this.lang, slug);
  };
  getLangText = slug => {
    const key = `${this.language.get()}.${slug}`;
    return getJSONvalue(this.lang, key);
  };

  getLangLink = slug => {
    return `/${this.language.get()}/${slug}`;
  };
}

export default Store;
