import React, { Component } from "react";
import CAbout from "../components/about";
import MainLayout from "../layout/MainLayout";

class about extends Component {
  render() {
    return (
      <MainLayout>
        <CAbout mobx={this.props.mobx} />
      </MainLayout>
    );
  }
}

export default about;
