import React, { Component } from "react";
import { observer } from "mobx-react";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import Loading from "../Loading";
import plane from "../../images/plane.jpg";
import truck from "../../images/truck.jpg";
import container from "../../images/container.jpg";

class Home extends Component {
  getDeriveryStep(steps) {
    return steps.map((step, index) => {
      return (
        <div key={`${step}+${index}`}>
          {index + 1}. {step}
        </div>
      );
    });
  }
  render() {
    if (this.props.mobx.loading.get()) {
      return <Loading />;
    }
    return (
      <div>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={37}
          totalSlides={3}
          interval={7000}
          isPlaying
        >
          <Slider>
            <Slide index={0}>
              <div
                style={{
                  backgroundImage: `url('${plane}')`,
                  height: "45vw",
                  maxHeight: "500px",
                  backgroundSize: "cover",
                  backgroundPositionX: "right",
                  backgroundPositionY: "center"
                }}
              />
            </Slide>
            <Slide index={1}>
              <div
                style={{
                  backgroundImage: `url('${truck}')`,
                  height: "45vw",
                  maxHeight: "500px",
                  backgroundSize: "cover",
                  backgroundPositionX: "right",
                  backgroundPositionY: "center"
                }}
              />
            </Slide>
            <Slide index={2}>
              <div
                style={{
                  backgroundImage: `url('${container}')`,
                  height: "45vw",
                  maxHeight: "500px",
                  backgroundSize: "cover",
                  backgroundPositionX: "right",
                  backgroundPositionY: "center"
                }}
              />
            </Slide>
          </Slider>
        </CarouselProvider>
        {/* <div
          style={{
            backgroundImage: `url('${plane}')`,
            height: "45vw",
            maxHeight: "500px",
            backgroundSize: "cover",
            backgroundPositionX: "right",
            backgroundPositionY: "center"
          }}
        /> */}
        <h1>{this.props.mobx.getLangText("home.translate")}</h1>
        <div>{this.props.mobx.getLangText("home.detail")}</div>
        <h1>{this.props.mobx.getLangText("home.deriverystep.title")}</h1>
        {this.getDeriveryStep(
          this.props.mobx.getLangText("home.deriverystep.detail")
        )}
        <h1>{this.props.mobx.getLangText("home.remark.title")}</h1>
        {this.getDeriveryStep(
          this.props.mobx.getLangText("home.remark.detail")
        )}
      </div>
    );
  }
}

export default observer(Home);
