import React, { Component } from "react";
import "./MainLayout.css";

export class MainLayout extends Component {
  render() {
    return <div id="mainlayout">{this.props.children}</div>;
  }
}

export default MainLayout;
