import React, { Component } from "react";
import Clanding from "../components/landing";

class landing extends Component {
  render() {
    return <Clanding mobx={this.props.mobx} />;
  }
}

export default landing;
