import React from "react";
import { Route } from "react-router-dom";
import Landing from "../pages/landing";
import Home from "../pages/home";
import About from "../pages/about";
import Service from "../pages/service";
import Contact from "../pages/contact";
import Store from "../mobx/store";
import Navbar from "../components/navbar";
import Footer from "../components/footer";

const store = new Store();

function createLocalizedRoutes(locale) {
  return (
    <div>
      {/* language navbar */}
      <Route
        path={`/${locale}`}
        component={() => <Navbar lang={locale} mobx={store} />}
      />
      <Route
        path={`/${locale}`}
        component={() => <Home mobx={store} />}
        exact
      />
      <Route
        path={`/${locale}/about_us`}
        component={() => <About mobx={store} />}
        exact
      />
      <Route
        path={`/${locale}/service`}
        component={() => <Service mobx={store} />}
        exact
      />
      <Route
        path={`/${locale}/contact_us`}
        component={() => <Contact mobx={store} />}
        exact
      />
      {/* <Route
        exact
        path={[`/${locale}`, `/${locale}/about_us`, `/${locale}/service`]}
        component={() => <Footer mobx={store} />}
      /> */}
      <Route path={`/${locale}`} component={() => <Footer mobx={store} />} />
    </div>
  );
}

export default (
  <div>
    <Route path="/" exact component={() => <Landing mobx={store} />} />
    {createLocalizedRoutes("en")}
    {createLocalizedRoutes("th")}
    {createLocalizedRoutes("cn")}
  </div>
);
