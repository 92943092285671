import React, { Component } from "react";
import { observer } from "mobx-react";
import "react-image-lightbox/style.css";
import Map from "./Map";
import Loading from "../Loading";
import pantipmap from "../../images/Maps-office-cargo.jpg";

class Contact extends Component {
  getBranches(branches) {
    return branches.map((branch, index) => {
      return (
        <div key={`${branch.title}+${index}`}>
          <h3>{branch.title}</h3>
          <div style={{ paddingLeft: "20px" }}>
            <h4>{this.props.mobx.getLangText("contact.address")}</h4>
            <div>{branch.address}</div>
            <h4>{this.props.mobx.getLangText("contact.telephone")}</h4>
            <div>
              {branch.telephone.map(tel => (
                <div>{tel}</div>
              ))}
            </div>
            <h4>{this.props.mobx.getLangText("contact.fax")}</h4>
            <div>{branch.fax}</div>
          </div>
          {branch.title === "Patunum office" ||
          branch.title === "สาขาประตูน้ำ" ||
          branch.title === "Pratunam 分公司" ? (
            <Map mapimg={pantipmap} />
          ) : null}
        </div>
      );
    });
  }

  getBanks(banks) {
    return banks.map((bank, index) => {
      return <div key={`${bank}+${index}`}>{bank}</div>;
    });
  }

  render() {
    if (this.props.mobx.loading.get()) {
      return <Loading />;
    }
    return (
      <div>
        <h1>{this.props.mobx.getLangText("contact.translate")}</h1>
        <div>
          <h2>
            <i className="fas fa-clock" />{" "}
            {this.props.mobx.getLangText("contact.detail.Office.title")}
          </h2>
          <div style={{ paddingLeft: "20px" }}>
            {this.props.mobx.getLangText("contact.detail.Office.detail")}
          </div>
        </div>
        <div>
          <h2>{this.props.mobx.getLangText("contact.detail.Branch.title")}</h2>

          <div style={{ paddingLeft: "20px" }}>
            {this.getBranches(
              this.props.mobx.getLangText("contact.detail.Branch.detail")
            )}
          </div>
        </div>
        <div>
          <h2>
            <i className="fas fa-money-check-alt" />{" "}
            {this.props.mobx.getLangText("contact.detail.Banking.title")}
          </h2>

          <div style={{ paddingLeft: "20px" }}>
            {this.getBanks(
              this.props.mobx.getLangText("contact.detail.Banking.detail")
            )}
          </div>
        </div>
        <div>
          <h2>
            <i className="fas fa-envelope" />{" "}
            {this.props.mobx.getLangText("Email")}
          </h2>

          <div style={{ paddingLeft: "20px" }}>
            {this.props.mobx.getLangText("contact.detail.Email")}
          </div>
        </div>
        <div>
          <h2>
            <i className="fab fa-line" /> Line official
          </h2>

          <div style={{ paddingLeft: "20px" }}>
            {this.props.mobx.getLangText("contact.detail.Line")}
          </div>
        </div>
        <div>
          <h2>
            <i className="fab fa-facebook-square" /> Facebook
          </h2>

          <div style={{ paddingLeft: "20px" }}>
            {this.props.mobx.getLangText("contact.detail.Facebook")}
          </div>
        </div>
        <div>
          <h2>
            <i className="fab fa-whatsapp-square" />{" "}
            {this.props.mobx.getLangText("contact.detail.WhatApp.title")}
          </h2>

          <div style={{ paddingLeft: "20px" }}>
            {this.props.mobx.getLangText("contact.detail.WhatApp.detail")}
          </div>
        </div>
      </div>
    );
  }
}

export default observer(Contact);
