import React, { Component } from "react";
import { observer } from "mobx-react";
import Loading from "../Loading";
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
  Markers,
  Marker
} from "react-simple-maps";
import world from "../../images/world-50m.json";

const wrapperStyles = {
  width: "100%",
  maxWidth: 980,
  margin: "0 auto"
};

const markers = [
  { name: "Indonesia", coordinates: [113.9213, 0.7893] },
  { name: "Singapore", coordinates: [103.8198, 1.3521] },
  { name: "Taiwan", coordinates: [120.9605, 23.6978] },
  { name: "Hong Kong", coordinates: [114.1095, 22.3964] },
  { name: "Nepal", coordinates: [84.124, 28.3949] },
  { name: "Maldives", coordinates: [73.2207, 3.2028] },
  { name: "Australia", coordinates: [133.7751, 25.2744] }
];

class Service extends Component {
  getServices(services) {
    return services.map((service, index) => {
      return (
        <div key={`${service}+${index}`}>
          {index + 1}. {service}
        </div>
      );
    });
  }

  getExportTransportations(transportations) {
    return transportations.map((transportation, index) => (
      <div key={`${transportation}+${index}`}>{transportation}</div>
    ));
  }

  getExportCountries(countries) {
    return countries.map((country, index) => {
      return (
        <div
          key={`${country}+${index}`}
          style={{ width: "50%", marginTop: "10px" }}
        >
          <span style={{ paddingLeft: "10vw" }}>
            <i className="fas fa-map-marker-alt" /> {country}
          </span>
        </div>
      );
    });
  }

  getProcesses(processes) {
    return processes.map((_process, index) => {
      return (
        <div key={`${_process}+${index}`}>
          <div>
            {index + 1}. {_process.title}
          </div>
          <div style={{ padding: "10px" }}>{_process.detail}</div>
        </div>
      );
    });
  }

  getAdditionalServices(services) {
    return services.map((_service, index) => {
      return (
        <div>
          {index + 1}. {_service}
        </div>
      );
    });
  }

  render() {
    if (this.props.mobx.loading.get()) {
      return <Loading />;
    }
    return (
      <div>
        <h1>{this.props.mobx.getLangText("service.detail.Service.title")}</h1>
        {this.getServices(
          this.props.mobx.getLangText("service.detail.Service.detail")
        )}
        <h1>{this.props.mobx.getLangText("service.detail.Export.title")}</h1>
        {/* {this.getExportTransportations(
          this.props.mobx.getLangText("service.detail.Export.transportations")
        )} */}
        <div style={wrapperStyles}>
          <ComposableMap
            projectionConfig={{
              scale: 205,
              rotation: [-11, 0, 0]
            }}
            width={980}
            height={551}
            style={{
              width: "100%",
              height: "auto"
            }}
          >
            <ZoomableGroup center={[70, 0]} disablePanning>
              <Geographies geography={world}>
                {(geographies, projection) =>
                  geographies.map(
                    (geography, i) =>
                      geography.id !== "ATA" && (
                        <Geography
                          key={i}
                          geography={geography}
                          projection={projection}
                          style={{
                            default: {
                              fill: "#ECEFF1",
                              stroke: "#607D8B",
                              strokeWidth: 0.75,
                              outline: "none"
                            },
                            hover: {
                              fill: "#607D8B",
                              stroke: "#607D8B",
                              strokeWidth: 0.75,
                              outline: "none"
                            },
                            pressed: {
                              fill: "#FF5722",
                              stroke: "#607D8B",
                              strokeWidth: 0.75,
                              outline: "none"
                            }
                          }}
                        />
                      )
                  )
                }
              </Geographies>
              <Markers>
                {markers.map((marker, i) => (
                  <Marker
                    key={i}
                    marker={marker}
                    style={{
                      default: { stroke: "#455A64" },
                      hover: { stroke: "#FF5722" },
                      pressed: { stroke: "#FF5722" }
                    }}
                  >
                    <g transform="translate(-12, -24)">
                      <path
                        fill="none"
                        strokeWidth="2"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeLinejoin="miter"
                        d="M20,9c0,4.9-8,13-8,13S4,13.9,4,9c0-5.1,4.1-8,8-8S20,3.9,20,9z"
                      />
                      <circle
                        fill="none"
                        strokeWidth="2"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeLinejoin="miter"
                        cx="12"
                        cy="9"
                        r="3"
                      />
                    </g>
                    <text
                      textAnchor="middle"
                      y={marker.markerOffset}
                      style={{
                        fontFamily: "Roboto, sans-serif",
                        fill: "#607D8B",
                        stroke: "none"
                      }}
                    >
                      {marker.name}
                    </text>
                  </Marker>
                ))}
              </Markers>
            </ZoomableGroup>
          </ComposableMap>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap"
          }}
        >
          {this.getExportCountries(
            this.props.mobx.getLangText("service.detail.Export.countries")
          )}
        </div>
        <h1>{this.props.mobx.getLangText("service.detail.Process.title")}</h1>
        {this.getProcesses(
          this.props.mobx.getLangText("service.detail.Process.processes")
        )}

        <h1>
          {this.props.mobx.getLangText("service.detail.Additional.title")}
        </h1>
        {this.getAdditionalServices(
          this.props.mobx.getLangText("service.detail.Additional.services")
        )}
      </div>
    );
  }
}

export default observer(Service);
