import React, { Component } from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
import { observer } from "mobx-react";
import { HamburgerButton } from "react-hamburger-button";
import LangingLogo from "../../images/logolanding.png";
import Logo from "../../images/logo.png";
import underline from "../../images/underline.png";
import "./navbar.css";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.props.mobx.language.set(this.props.lang);
  }

  handleClick() {
    this.setState({
      open: !this.state.open
    });
  }

  render() {
    if (this.props.mobx.loading.get()) {
      return <div />;
    }
    return (
      <header>
        <div className="desktop">
          <div className="logo-head">
            <div className="logo-wrapper">
              <div className="triangle" />
              <img src={LangingLogo} alt="Logo" className="brand logo" />
            </div>
            <div className="text-center">
              <h1>First Time Aircargo</h1>
              <div
                className="underline"
                style={
                  this.props.mobx.language.get() === "th"
                    ? {
                        marginBottom: "10px"
                      }
                    : { marginBottom: "12px" }
                }
              >
                <img
                  src={underline}
                  style={{ width: "295px", height: "auto" }}
                  alt="icon"
                />
              </div>
              <div>Exported Simplified</div>
            </div>
            <div className="language">
              <Link
                to="/en"
                className={
                  this.props.mobx.language.get() === "en" ? "active" : ""
                }
              >
                EN
              </Link>
              {" / "}
              <Link
                to="/th"
                className={
                  this.props.mobx.language.get() === "th" ? "active" : ""
                }
              >
                TH
              </Link>
              {" / "}
              <Link
                to="/cn"
                className={
                  this.props.mobx.language.get() === "cn" ? "active" : ""
                }
              >
                CN
              </Link>
            </div>
          </div>
          <div className="link-head">
            {/* <Nav> */}
            <NavLink
              exact={true}
              to={this.props.mobx.getLangLink("")}
              activeClassName="active"
            >
              {this.props.mobx.getLangText("home.translate")}
            </NavLink>
            <NavLink
              to={this.props.mobx.getLangLink("about_us")}
              activeClassName="active"
            >
              {this.props.mobx.getLangText("about.translate")}
            </NavLink>
            <NavLink
              to={this.props.mobx.getLangLink("service")}
              activeClassName="active"
            >
              {this.props.mobx.getLangText("service.translate")}
            </NavLink>
            <NavLink
              to={this.props.mobx.getLangLink("contact_us")}
              activeClassName="active"
            >
              {this.props.mobx.getLangText("contact.translate")}
            </NavLink>
            {/* </Nav> */}
          </div>
        </div>
        <div className="mobile">
          <div className="logo-mobile-wrapper">
            <img src={Logo} alt="logo" />
          </div>
          <div className="hamburger">
            <HamburgerButton
              open={this.state.open}
              onClick={this.handleClick}
            />
          </div>
          <div
            className={`navlink ${this.state.open ? "active" : "non-active"}`}
          >
            <div>
              {" "}
              <NavLink
                exact={true}
                to={this.props.mobx.getLangLink("")}
                activeClassName="active"
                onClick={this.handleClick}
              >
                {this.props.mobx.getLangText("home.translate")}
              </NavLink>
            </div>
            <div>
              {" "}
              <NavLink
                to={this.props.mobx.getLangLink("about_us")}
                activeClassName="active"
                onClick={this.handleClick}
              >
                {this.props.mobx.getLangText("about.translate")}
              </NavLink>
            </div>
            <div>
              {" "}
              <NavLink
                to={this.props.mobx.getLangLink("service")}
                activeClassName="active"
                onClick={this.handleClick}
              >
                {this.props.mobx.getLangText("service.translate")}
              </NavLink>
            </div>
            <div>
              {" "}
              <NavLink
                to={this.props.mobx.getLangLink("contact_us")}
                activeClassName="active"
                onClick={this.handleClick}
              >
                {this.props.mobx.getLangText("contact.translate")}
              </NavLink>
            </div>
            <div>
              <Link
                to="/en"
                className={
                  this.props.mobx.language.get() === "en" ? "active" : ""
                }
              >
                EN
              </Link>
              {" / "}
              <Link
                to="/th"
                className={
                  this.props.mobx.language.get() === "th" ? "active" : ""
                }
              >
                TH
              </Link>
              {" / "}
              <Link
                to="/cn"
                className={
                  this.props.mobx.language.get() === "cn" ? "active" : ""
                }
              >
                CN
              </Link>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(observer(Navbar));
