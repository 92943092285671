import React, { Component } from "react";
import CHome from "../components/home";
import MainLayout from "../layout/MainLayout";

export class Home extends Component {
  render() {
    return (
      <MainLayout>
        <CHome mobx={this.props.mobx} />
      </MainLayout>
    );
  }
}

export default Home;
