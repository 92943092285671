import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import LangingLogo from "../../images/logolanding.png";
import Loading from "../Loading";
import "./landing.css";

class Landing extends Component {
  render() {
    if (this.props.mobx.loading.get()) {
      return <Loading />;
    }
    return (
      <div className="fullscreen">
        <div className="logo-background" />
        <img src={LangingLogo} alt="logo" />
        <div id="landing">
          {/* <div className="title">
            {this.props.mobx.getFixLangText("en.landing.title")}
          </div> */}
          <div className="detail">
            {this.props.mobx.getFixLangText("en.landing.detail")}
          </div>
          <div className="link">
            <Link to="/th">
              <button>ภาษาไทย</button>
            </Link>
            <Link to="/en">
              <button>English</button>
            </Link>
            <Link to="/cn">
              <button>中文</button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(Landing);
