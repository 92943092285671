import React, { Component } from "react";
import CContact from "../components/contact";
import MainLayout from "../layout/MainLayout";

class contact extends Component {
  render() {
    return (
      <MainLayout>
        <CContact mobx={this.props.mobx} />
      </MainLayout>
    );
  }
}

export default contact;
