import React, { Component } from "react";
import { observer } from "mobx-react";
import ProgressiveImage from "react-progressive-image-loading";
import Loading from "../Loading";
import ship from "../../images/ship.jpg";
import shiptiny from "../../images/shiptiny.jpg";
import "./about.css";

class About extends Component {
  getReasons(reasons) {
    return reasons.map(reason => {
      return (
        <div key={reason.title}>
          <h2>{reason.title}</h2>
          <div>{reason.detail}</div>
        </div>
      );
    });
  }
  render() {
    if (this.props.mobx.loading.get()) {
      return <Loading />;
    }
    return (
      <div id="about">
        <h1 align="center">{this.props.mobx.getLangText("about.translate")}</h1>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div className="responsive" style={{ textAlign: "center" }}>
            <ProgressiveImage
              preview={shiptiny}
              src={ship}
              transitionTime={500}
              transitionFunction="ease"
              render={(src, style) => (
                <img
                  style={{ width: "90%", height: "auto", borderRadius: "5px" }}
                  src={src}
                  alt="ship"
                />
              )}
            />
          </div>
          <div
            className="responsive"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center"
            }}
          >
            <div
              style={{
                padding: "10px"
              }}
            >
              {this.props.mobx.getLangText("about.detail")}
              <hr />
            </div>
          </div>
        </div>
        <div style={{ margin: "25px" }}>
          <h1>
            <u>{this.props.mobx.getLangText("about.reason.title")}</u>
          </h1>
          <div>
            {this.getReasons(
              this.props.mobx.getLangText("about.reason.detail")
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default observer(About);
