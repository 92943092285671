import React, { Component } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import "./footer.css";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openWhatApp: false
    };
    this.toggleWhatApp = this.toggleWhatApp.bind(this);
  }
  toggleWhatApp() {
    this.setState({
      openWhatApp: !this.state.openWhatApp
    });
  }
  render() {
    if (this.props.mobx.loading.get()) {
      return <div />;
    }
    return (
      <footer>
        <div className="firstchild">
          <div className="social">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/First-time-air-cargo-2153311571583416/"
            >
              <i className="fab fa-facebook-square" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://line.me/ti/p/~@firsttimeaircargo"
            >
              <i className="fab fa-line" />
            </a>
            <Link to={this.props.mobx.getLangLink("contact_us")}>
              <i className="fab fa-whatsapp-square" />
            </Link>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:FTC@FTC-CARGO.COM?subject=First Time Aircargo website"
            >
              <i className="fas fa-envelope-square" />
            </a>
          </div>
          <div className="link">
            <Link to={this.props.mobx.getLangLink("")}>
              {this.props.mobx.getLangText("home.translate").toUpperCase()}
            </Link>
            <Link to={this.props.mobx.getLangLink("about_us")}>
              {this.props.mobx.getLangText("about.translate").toUpperCase()}
            </Link>
            <Link to={this.props.mobx.getLangLink("service")}>
              {this.props.mobx.getLangText("service.translate").toUpperCase()}
            </Link>
            <Link to={this.props.mobx.getLangLink("contact_us")}>
              {this.props.mobx.getLangText("contact.translate").toUpperCase()}
            </Link>
          </div>
        </div>
        <div className="secondchild">
          FIRST TIME AIRCARGO
          <hr />
          Exported Simplified
          <hr />
          ALL RIGHTS RESERVED
        </div>
        {/* <h1>{this.props.mobx.getLangText("contact.translate")}</h1>
        <div className="med-logo">
          <div className="footer-logo-wrapper">
            <img src="" alt="Logo" className="logo" />
          </div>
          <div>
            <div>{this.props.mobx.getLangText("Address")}</div>
            <div>{this.props.mobx.getLangText("Phone")}</div>
            <div>{this.props.mobx.getLangText("Email")}</div>
          </div>
        </div> */}
      </footer>
    );
  }
}

export default observer(Footer);
