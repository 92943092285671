import React, { Component } from "react";
import Logo from "../../images/logo.png";
import "./loading.css";

class Loading extends Component {
  render() {
    return (
      <div id="loading">
        <img src={Logo} alt="logo" />
        <span>LOADING . . .</span>
      </div>
    );
  }
}

export default Loading;
