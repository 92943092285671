import React, { Component } from "react";
import Lightbox from "react-image-lightbox";

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  render() {
    if (!this.props.mapimg) return;
    const { isOpen } = this.state;
    return (
      <div>
        <button
          type="button"
          onClick={() => this.setState({ isOpen: true })}
          style={{
            fontSize: "1.5em",
            padding: "10px",
            margin: "10px 20px",
            color: "white",
            background: "rgba(182, 48, 61,0.9)",
            borderRadius: "15px"
          }}
        >
          Open Map
        </button>
        {isOpen && (
          <Lightbox
            mainSrc={this.props.mapimg}
            onCloseRequest={() => this.setState({ isOpen: false })}
          />
        )}
      </div>
    );
  }
}

export default Map;
